import React, { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Layout from '../../components/layouts/layout.component'
import Text from '../common/text.component'
import Button from '../common/button.component'
import setUserRoute from '../../context/user/actions/setUserRoute'
import { default as SuccessIcon } from '../../assets/icons/success_icon.svg'

const PAGE_TITLE = 'Cambio de contraseña - Carmuv'
const META_DESCRIPTION =
  'Conocé todos los autos que se comercializan en Argentina. Encontrá en nuestro catálogo fichas tecnicas, fotos e información actualizada.'
const SUCCESS_TITLE = '¡Listo!'
const SUCCESS_SUBTITLE = 'Ya podés usar tu nueva contraseña para volver a ingresar.'
const LOGIN_BTN = 'Ingresar a mi cuenta'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '31.75rem',
    height: '39rem',
    alignItems: 'center',
    paddingTop: '6rem',
    [theme.breakpoints.down('sm')]: {
      width: '21.875rem',
    },
  },
  spacing: {
    margin: '0 auto 3.5rem auto',
  },
  subTitle: {
    marginTop: '1rem',
    width: '20rem',
  },
  button: {
    height: '3rem',
    marginTop: '3rem',
    fontSize: '16px',
    fontWeight: 600,
  },
}))

const PasswordChangeSuccess = ({ location, pageContext, goToLogin = () => {}, userDispatch }) => {
  const classes = useStyles()

  useEffect(() => {
    setUserRoute(userDispatch, {
      prevRoute: null,
    });
  }, [])

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader={false}
      description={META_DESCRIPTION}
      onGoBack={() => goToCuenta(1)}
    >
      <Box display="flex" flexDirection="row" className={classes.container}>
        <Box className={classes.spacing}>
          <SuccessIcon />
        </Box>
        <Box className={classes.spacing} display="flex" flexDirection="column" alignItems="center">
          <Text large semibolder>
            {SUCCESS_TITLE}
          </Text>
          <Text className={classes.subTitle} little g600 center>
            {SUCCESS_SUBTITLE}
          </Text>
        </Box>
        <Button className={classes.button} onClick={goToLogin} primary fullWidth disableRipple>
          {LOGIN_BTN}
        </Button>
      </Box>
    </Layout>
  )
}

export default PasswordChangeSuccess
