import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PasswordTextField from '../common/PasswordTextField.component'
import Text from '../common/text.component'
import Button from '../common/button.component'
import Link from '../common/Link.component'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import clsx from 'clsx'

const CONFIRMAR_LABEL = "Confirmar"
const TITLE_PASSWORD_CHANGE = "Cambiar la contraseña"
const CURRENT_PASSWORD_LABEL = "Contraseña actual"
const NEW_PASSWORD_LABEL = "Nueva contraseña"
const REPEAT_PASSWORD_LABEL = "Repetir la contraseña"

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: 'auto',
    width: 'inherit',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 2rem 2rem',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      height: '45.2rem',
      paddingRight: '5rem',
    },
  },
  form: {
    margin: 'auto',
    marginTop: '2rem',
    width: '25rem',
  },
  title: {
    display: 'block',
    textAlign: 'center',
    marginTop: '0',
    marginBottom: '2rem',
    color: theme.palette.grayScale.g500,
  },
  textInput: {
    width: '100%',
    marginTop: '0',
    marginBottom: '2.9rem',
    '& input': {
      padding: '0.9063rem 1.5rem',
    },
    '& label': {
      fontSize: '18px',
      paddingLeft: '1rem !important',
      color: `${theme.palette.grayScale.g600} !important`,
      transform: 'translate(.438rem, 1rem) scale(0.8)',
    },
    '& .MuiFormLabel-root': {
      color: `${theme.palette.grayScale.g700} !important`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0.875rem, -1.1rem) scale(0.65) !important',
    },
    '& .MuiBox-root':{
      marginLeft:'0.375rem !important',
      width:'95% !important'
    },
  },
  newPassword: {
    marginBottom: '4.1rem',
  },
  button: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto 6.25rem auto',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 auto 6rem auto',
    }
  },
  boxArrowBack: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  arrowBack: {
    marginRight: '0.375rem',
  },
  arrowBackText: {
    lineHeight: '1.5rem',
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
      marginLeft: '1.725rem',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '2.5rem',
    },
    '& :hover': {
      color: theme.palette.primary.dark,
    },
  },
}))

const PasswordChange = ({
  errorMessages,
  loading = false,
  newPasswordHelper,
  handleSubmit = () => {},
  goBack = () => {},
  isMobile,
}) => {
  const classes = useStyles()
  const [fields, setFields] = useState({
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  })

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields }
    newFields[key] = value
    setFields(newFields)
  }

  return (
    <>
      {!isMobile && (
        <Box className={classes.link}>
          <Link inverted onClick={goBack}>
            <Box className={classes.boxArrowBack}>
              <ArrowBackIosIcon className={classes.arrowBack} />
              <Text small semibolder className={classes.arrowBackText}>
                Volver
              </Text>
            </Box>
          </Link>
        </Box>
      )}
      <Box className={classes.container}>
        <div className={classes.form}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text className={classes.title} large semibolder>
              {TITLE_PASSWORD_CHANGE}
            </Text>
          </Box>
          <PasswordTextField
            error={errorMessages && errorMessages.currentPassword?.error}
            helperText={errorMessages && errorMessages.currentPassword?.error}
            FormHelperTextProps={{ component: 'span' }}
            className={classes.textInput}
            label={CURRENT_PASSWORD_LABEL}
            value={fields.currentPassword}
            onChange={(evt) => handleTextFieldChange('currentPassword', evt.target.value)}
            variant="outlined"
          />
          <PasswordTextField
            error={errorMessages && errorMessages.newPassword?.error}
            helperText={errorMessages?.newPassword?.error || newPasswordHelper}
            FormHelperTextProps={{ component: 'span' }}
            className={clsx(classes.textInput, classes.newPassword)}
            label={NEW_PASSWORD_LABEL}
            value={fields.newPassword}
            onChange={(evt) => handleTextFieldChange('newPassword', evt.target.value)}
            variant="outlined"
          />
          <PasswordTextField
            error={errorMessages && errorMessages.repeatPassword?.error}
            helperText={errorMessages && errorMessages.repeatPassword?.error}
            FormHelperTextProps={{ component: 'span' }}
            className={classes.textInput}
            label={REPEAT_PASSWORD_LABEL}
            value={fields.repeatPassword}
            onChange={(evt) => handleTextFieldChange('repeatPassword', evt.target.value)}
            variant="outlined"
          />
          <Button
            className={classes.button}
            onClick={() => handleSubmit(fields)}
            primary
            disabled={loading}
          >
            {CONFIRMAR_LABEL}
          </Button>
        </div>
      </Box>
    </>
  )
}

export default PasswordChange
