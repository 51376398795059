const PRICE_MULTIPLIER = 1;

export const formatPrice = (price, symbol) =>
  `${symbol ? symbol : '$'} ${new Intl.NumberFormat('es-Ar', { minimumFractionDigits: 0 }).format(Number(price || 0))}`;


export const getPrice = (priceList) => {
  if (priceList.length > 0) {
    const youngestPrice = priceList.find((price) => {
      return price.price != null;
    });

    const finalPrice = youngestPrice.price * PRICE_MULTIPLIER;
    return formatPrice(finalPrice);
  }
};

export const formaterNumber = num =>
  new Intl.NumberFormat('es-Ar').format(Number(num));

export const formatPricePills = (price, symbol) =>
  `${symbol ? symbol : '$'}${new Intl.NumberFormat('es-Ar', { minimumFractionDigits: 0 }).format(Number(price))}`;

/**
* Formatea un numero a un string
* con 2 decimales en formato es-ar
* @param {number} value Numero a formatear
*/
export const formatFractionDigits = (value) => {
  const esArNumber = formaterNumber(value);
  const splittedNumber = esArNumber.split(',');

  // Si el numero tiene parte decimal
  if (splittedNumber[1]) {
    // y tiene menos de 2 decimales
    if (splittedNumber[1].length < 2) {
      // Se completa con 0s
      splittedNumber[1] = splittedNumber[1].padEnd(2, '0');
    } else {
      // Si el numero tiene mas decimales se trunca a 2 decimales
      splittedNumber[1] = splittedNumber[1].substring(0, 2);
    }

    return splittedNumber.join(',');
  }

  // Si el numero no tiene decimales se agregan 0s
  return esArNumber + ',00';
};
