import React from 'react';
import MuiCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadow.card,
    borderRadius: theme.borderRadius.main,
    borderColor: `1px solid ${theme.palette.grayScale.g200}`,
    transition: theme.transition.main,
  },
  hoverable: {
    '&:hover':{
      boxShadow: theme.shadow.hover,
      transform: 'translateY(-3px)'
    }
  },
}));


const Card = ({ className, hoverable = false, ...otherProps }) => {
  const classes = useStyles();

  return (
    <MuiCard
      className={clsx(
        classes.root,
        className,
        { [classes.hoverable]:hoverable }
      )}
      {...otherProps}
    />
  );
};

export default Card;
