import React, { useState, useEffect } from 'react'
import { Box, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Layout from '../../components/layouts/layout.component'
import PasswordChange from '../../components/account/PasswordChange.component'
import { goToCuenta } from '../../api/navigation'
import { validatePassword, validateRepeatPassword, longerPasswordRegex } from '../../utils/validators.utils'
import ErrorMessage from '../../components/common/ErrorMessage.component'
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { UPDATE_PASSWORD_LOGGED_IN } from '../../mutations';
import { logout } from '../../api/authorization'
import { LOGOUT } from '../../mutations/logout'

const PAGE_TITLE = 'Cambio de contraseña - Carmuv'
const META_DESCRIPTION =
  'Conocé todos los autos que se comercializan en Argentina. Encontrá en nuestro catálogo fichas tecnicas, fotos e información actualizada.'

const CURRENT_PASSWORD_EMPTY_ERROR_MESSAGE = "Escribí tu contraseña actual."
const REPEAT_PASSWORD_ERROR_EMPTY_MESSAGE = "Por favor, repetí tu contraseña."
const CURRENT_PASSWORD_ERROR_MESSAGE = "Contraseña incorrecta. Intentá de nuevo."
const REPEAT_PASSWORD_ERROR_MESSAGE = "Las contraseñas no coinciden. Intentá de nuevo."
const NEW_PASSWORD_MESSAGE = "Tu contraseña tiene que tener al menos: 8 caracteres, 1 mayúscula, 1 minúscula y 1 número."

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: '5rem',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '4.5rem auto 0 auto',
    },
  },
  sectionLeyendSecondary: {
    width: '60%',
    [theme.breakpoints.down('md')]:{
      width: '90%',
    },
    position: 'absolute',
    height: 'auto',
    padding: '0 10px 0 10px',
  },
  absolutePosition: { position: 'absolute' },
}))

const PasswordChangeContainer = ( { location, pageContext, goToSuccessPage }) => {
  const classes = useStyles()
  const theme = useTheme()
  const client = useApolloClient()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [wrongPasswordError, setWrongPasswordError] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  const [updatePassword, {
    data: updateResponse,
    loading
  }] = useMutation(UPDATE_PASSWORD_LOGGED_IN);

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: () => {
      goToSuccessPage()
      logout(client, false)
    },
  })

  useEffect(() => {
    const success = updateResponse?.updatePasswordLoggedIn?.success
    const wrongPassword = updateResponse?.updatePasswordLoggedIn?.wrongPassword
    if (!loading && success) {
      setWrongPasswordError(false)
      logoutMutation()
    } else if(!loading && !success && wrongPassword) {
      setWrongPasswordErrorMessage()
    }
  }, [loading]);

  const validateCurrentPassword = (value, emptyMessage) => {
    if (!value)
      return { valid: false, error: emptyMessage }
    else if (!wrongPasswordError)
      return {valid: true, error: ''}
  }

  const validateFields = (fields) => {
    const isValidCurrentPassword = validateCurrentPassword(
      fields.currentPassword,
      <ErrorMessage absolute icon={false}>{CURRENT_PASSWORD_EMPTY_ERROR_MESSAGE}</ErrorMessage>
    )

    const isValidNewPassword = validatePassword(
      fields.newPassword,
      <ErrorMessage absolute icon={false}>{NEW_PASSWORD_MESSAGE}</ErrorMessage>,
      <ErrorMessage absolute icon={false}>{NEW_PASSWORD_MESSAGE}</ErrorMessage>,
      longerPasswordRegex
    )

    const isValidRepeatPassword = validateRepeatPassword(
      fields.newPassword,
      fields.repeatPassword,
      <ErrorMessage absolute icon={false}>{REPEAT_PASSWORD_ERROR_MESSAGE}</ErrorMessage>,
      <ErrorMessage absolute icon={false}>{REPEAT_PASSWORD_ERROR_EMPTY_MESSAGE}</ErrorMessage>
    )

    const valid =
      !wrongPasswordError &&
      isValidCurrentPassword.valid &&
      isValidNewPassword.valid &&
      isValidRepeatPassword.valid
    
    const errors = {
      currentPassword: wrongPasswordError ? errorMessages.currentPassword : isValidCurrentPassword,
      newPassword: isValidNewPassword,
      repeatPassword: isValidRepeatPassword,
    }

    setErrorMessages(errors)
    return valid
  }

  const setWrongPasswordErrorMessage = () => {
    setErrorMessages({
      ...errorMessages,
      currentPassword: {
        valid: false,
        error: <ErrorMessage icon={false}>{CURRENT_PASSWORD_ERROR_MESSAGE}</ErrorMessage>,
      },
    })
  }

  const handleSubmit = (fields) => {
    setWrongPasswordError(false)
    const isValid = validateFields(fields)
    if (isValid) {
      updatePassword({
        variables: {
          currentPassword: fields.currentPassword,
          newPassword: fields.newPassword,
        },
      })
    }
  }

  const NEW_PASSWORD_HELPER = (
    <Box className={classes.absolutePosition} width="100%" fontSize="11px" ml="0.625rem">
      {NEW_PASSWORD_MESSAGE}
    </Box>
  )
  
  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader={false}
      description={META_DESCRIPTION}
      onGoBack={() => goToCuenta(1)}
      showMobileBackIcon
      hideMobileUserMenu
    >
      <Box display="flex" flexDirection="row" className={classes.container}>
        <PasswordChange
          errorMessages={errorMessages}
          loading={loading}
          newPasswordHelper={NEW_PASSWORD_HELPER}
          handleSubmit={handleSubmit}
          goBack={goToCuenta}
          isMobile={isMobile}
        />
      </Box>
    </Layout>
  )
}

export default PasswordChangeContainer
