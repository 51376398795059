import React from 'react'
import { Router } from '@reach/router'
import AccountPageContainer from '../containers/account/AccountPage.container'
import PasswordChangePageContainer from '../containers/account/PasswordChange.container'
import PasswordChangeSuccess from '../components/account/PasswordChangeSuccess.component'
import { goToIngresar, goToCambioPasswordExitoso } from '../api/navigation'
import useIsLoggedUser from '../utils/hooks/useIsLoggedUser'
import { useAppContext } from '../context/AppContext'
import { ListaCatalogoLoading } from '../components/catalogo/lista/ListaCatalogo.component'

const AccountPage = (props) => {
  const { user, userDispatch } = useAppContext()
  const { isLoggedIn, loadingLogin } = useIsLoggedUser()

  if (loadingLogin) return <ListaCatalogoLoading />

  return (
    <Router basepath="/cuenta">
      { isLoggedIn ? 
        <>
          <AccountPageContainer profile={user.profile} {...props} path="/" />
          <PasswordChangePageContainer {...props} goToSuccessPage={() => goToCambioPasswordExitoso()} path="/cambio" />
        </> :
        <PasswordChangeSuccess {...props} path="/cambio-exitoso" goToLogin={() => goToIngresar()} userDispatch={userDispatch}/>
      }
    </Router>
  )
}

export default AccountPage
