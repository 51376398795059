import React from 'react'
import AccountData from '../../components/account/AccountData.component'
import { Box, makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  container: {
    width: '25rem',
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.125rem 0 1.125rem'
    },
  },
}))

const AccountDataContainer = ({ profile = {} }) => {
  const classes = useStyle()
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.container}>
          <AccountData profile={profile} />
        </Box>
      </Box>
    </>
  )
}

export default AccountDataContainer
