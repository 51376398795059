import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Tabs, Tab, useTheme, useMediaQuery } from '@material-ui/core';
import Layout from '../../components/layouts/layout.component';
import PersonalDataContainer from './PersonalData.container';
import AccountDataContainer from './AccountData.container';
import HiddenOn from '../../components/common/HiddenOn.component';
import ModalExitPrompt from '../../components/common/ModalExitPrompt.component';
import { showModalExitPrompt } from '../../context/modalExitPrompt/utils';
import { useAppContext } from '../../context/AppContext';
import setFormHasData from '../../context/modalExitPrompt/actions/setFormHasData';
import setClearData from '../../context/modalExitPrompt/actions/setClearData';
import ReservationData from '../../components/account/ReservationData.component';
import BudgetGeneratedData from '../../components/account/BudgetGeneratedData.component';
const PAGE_TITLE = 'Catálogo de Autos - Carmuv';
const META_DESCRIPTION =
  'Conocé todos los autos que se comercializan en Argentina. Encontrá en'
  + ' nuestro catálogo fichas tecnicas, fotos e información actualizada.';
const subtitleModalPrompt = 'Si salís, no se guardará la información que cargaste.';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginLeft: '5rem',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '4.5rem auto 0 auto',
    },
  },
  aside: {
    width: '18.375rem',
  },
  tabs: {
    height: '38.75rem',
    width: '17.5rem',
    borderRight: `1px solid ${theme.palette.tertiary.background}`,
  },
  selectedTab: {
    color: theme.palette.grayScale.g600,
    fontWeight: '600 !important',
    backgroundColor: theme.palette.primary.light2,
    borderRadius: '1.125rem',
  },
  rootTab: {
    textTransform: 'none',
    fontWeight: 400,
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  divider: {
    minHeight: 0,
    margin: '1rem 0.75rem 1rem 0',
    padding: 0,
    opacity: '1 !important',
    borderTop: `1px solid ${theme.palette.tertiary.background}`,
  },
  ReservationTabPanel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0',
    margin: '0',
    gap: '16px',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '580px',
      marginTop: '16px',
    },
  }
}));

const TAB_PANEL_LABEL = {
  0: 'Datos personales',
  1: 'Datos de la cuenta',
  2: 'Mis reservas',
  3: 'Mis presupuestos',
};

function TabPanel({ children, value, index, ...other }) {
  return ( value !== index ? null :
    <Box
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      m="0 auto"
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

let tabValueModal = null;
const AccountPageContainer = ({ profile, location, pageContext }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { modalExitPrompt, modalExitPromptDispatch } = useAppContext();
  const [tabValue, setTabValue] = useState(location?.state?.index || 0);
  const [modalDependency, setModalDependency] = useState(false);

  const handleChange = (event, newValue) => {
    pageContext.breadcrumb.crumbs[2].crumbLabel = event.target.outerText;
    setModalDependency(!modalDependency);
    // Si el formulario tiene modificaciones => guarda el valor del tab objetivo en la variable tabValueModal
    // Si el formulario no tiene modificaciones => cambia de tab
    modalExitPrompt.formHasData ? tabValueModal = newValue : setTabValue(newValue);
  };

  useEffect(() => {
    // Al confirmar el modal de salir => cambia de tab
    modalExitPrompt.confirmExitPrompt && setTabValue(tabValueModal || 0);
  }, [modalExitPrompt.confirmExitPrompt]);

  useEffect(() => {
    if (pageContext.breadcrumb.crumbs.length < 3) {
      pageContext.breadcrumb.crumbs.push({ pathname: '/cuenta#', crumbLabel: 'Datos personales' });
    }
    pageContext.breadcrumb.crumbs[2].crumbLabel = TAB_PANEL_LABEL[location?.state?.index || 0];
    const newIndex = { // la linea divisoria incrementa en 1 los items siguientes
      2: 3,
      3: 4,
    };
    setTabValue([2, 3].includes(location?.state?.index)
      ? newIndex[location?.state?.index]
      : (location?.state?.index || 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const resetModalContext = () => {
    setClearData(modalExitPromptDispatch);
  };

  const handleFormEmpty = (isEmpty) => {
    setFormHasData(modalExitPromptDispatch, !isEmpty);
  };
  const showModalExitFunction = (prevRoute, nextRoute) => showModalExitPrompt(prevRoute, nextRoute, modalExitPrompt);
  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader={!isMobile}
      description={META_DESCRIPTION}
      capitalizeCrumbs
    >
      <ModalExitPrompt
        subtitle={subtitleModalPrompt}
        showModal={showModalExitFunction}
        manualDependency={modalDependency}
      />
      <Box display="flex" flexDirection="row" className={classes.container}>
        <HiddenOn mobile>
          <Box className={classes.aside}>
            <Tabs
              orientation="vertical"
              value={tabValue}
              onChange={handleChange}
              className={classes.tabs}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <Tab
                disableRipple
                className={classes.rootTab}
                classes={{ selected: classes.selectedTab }}
                label={TAB_PANEL_LABEL[0]}
              />
              <Tab
                disableRipple
                className={classes.rootTab}
                classes={{ selected: classes.selectedTab }}
                label={TAB_PANEL_LABEL[1]}
              />
              <Tab label="" className={classes.divider} disabled />
              <Tab
                disableRipple
                className={classes.rootTab}
                classes={{ selected: classes.selectedTab }}
                label={TAB_PANEL_LABEL[2]}
              />
              <Tab
                disableRipple
                className={classes.rootTab}
                classes={{ selected: classes.selectedTab }}
                label={TAB_PANEL_LABEL[3]}
              />
            </Tabs>
          </Box>
        </HiddenOn>
        <TabPanel value={tabValue} index={0}>
          {profile.email && <PersonalDataContainer
            profile={profile}
            isMobile={isMobile}
            onFormEmpty={(isEmpty) => handleFormEmpty(isEmpty)}
            resetModalContext={resetModalContext}
            handleFormEmpty={handleFormEmpty}
          />}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AccountDataContainer profile= { profile }/>
        </TabPanel>
        <TabPanel value={tabValue} index={3} className={classes.ReservationTabPanel} >
          <ReservationData/>
        </TabPanel>
        <TabPanel value={tabValue} index={4} className={classes.ReservationTabPanel}>
          <BudgetGeneratedData/>
      </TabPanel>
      </Box>
    </Layout>
  );
};

export default AccountPageContainer;
