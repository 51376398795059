import { ROUTES_MAP_HEADER_FOOTER } from './constants'

export const showBudgetExitPrompt = (
  prevRoute,
  nextRoute,
  preventExitPrompt,
  budget
) => {
  const patternMainBudget = RegExp(/(\/presupuesto\/[^\/]+\/[^\/]+\/[^\/]+\/)/)
  const resultPatternBudgetRoute = patternMainBudget.test(nextRoute)
  const isInMainBudget = patternMainBudget.test(prevRoute)
  const navigateToHeaderFooter = ROUTES_MAP_HEADER_FOOTER.includes(nextRoute)
  const navigateToMainBudget = resultPatternBudgetRoute
  const navigateBudgetSubRoute = nextRoute.includes('presupuesto')
  const isPatternReservCompleted = prevRoute.includes('reserva-completada')

  if (isPatternReservCompleted) {
    return false
  }
  // si preventExitPrompt === true en el state del navigation
  if (preventExitPrompt) {
    // oculta el modal de manera forzada
    return false
  }
  // si es el boton volver
  if (navigateToMainBudget) {
    // muestra el modal si hay datos en el formulario
    return budget.formHasData
  }
  // si no es botón volver, pero es una subruta de presupuesto
  else if (navigateBudgetSubRoute) {
    // oculta el modal
    return false
  }
  // si es un navigate del header o footer y la ruta origen no esta en la pantalla principal de budget
  else if (navigateToHeaderFooter && !isInMainBudget) {
    // muestra el modal
    return true
  } else {
    // muestra el modal si hay al menos 1 auto usado o una linea de credito cargada
    return budget.usedCars.length || budget.creditLine.financedAmount
  }
}

export const showModalExitPrompt = (prevRoute, nextRoute, modalExitPrompt) => {
  if (prevRoute !== nextRoute) {
    return modalExitPrompt.formHasData
  }
}
