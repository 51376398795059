import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import TextField from '../common/TextField.component'
import Button from '../common/button.component'

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '31.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '21.875rem',
    },
  },

  textInput: {
    width: '100%',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2.5rem',
    },
    '& input': {
      padding: '.9688rem 1.5rem',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.grayScale.g400,
      backgroundColor: theme.palette.grayScale.g01,
      borderColor: theme.palette.grayScale.g300,
    },
    '& label': {
      paddingBottom: '0.25rem',
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1.313rem',
      paddingLeft: '1rem',
      color: `${theme.palette.grayScale.g600} !important`,
      transform: 'translate(.438rem, 1rem) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0.875rem, -1.25rem) scale(0.75) !important',
    },
    '& fieldset': {
      borderColor: theme.palette.grayScale.g300,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(6),
    },
    marginLeft: '0.25rem',
    height: '1.5rem',
    padding: '0.25rem',
    fontWeight: '600',
    fontSize: '1rem',
    textAlign: 'left',
    color: theme.palette.primary.main2,
    border: '1px solid transparent',
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: theme.palette.grayScale.g700,
    },
    '&:focus': {
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
      border: '1px solid',
      borderRadius: theme.borderRadius.small,
    },
    '&:active': {
      border: '1px solid transparent',
      color: theme.palette.button.active,
    },
  },
}))

const AccountData = ({ profile = {} }) => {
  const classes = useStyle()

  return (
    <Box className={classes.container}>
      <TextField
        className={classes.textInput}
        label="E-mail"
        value={profile.email}
        variant="outlined"
        inputProps={{
          maxLength: '256',
        }}
        disabled
      />
      <TextField
        className={classes.textInput}
        label="Contraseña"
        value={'********'}
        variant="outlined"
        inputProps={{
          maxLength: '8',
          type: 'password',
        }}
        disabled
      />
      <Button
        href="/cuenta/cambio"
        data-test-id="input-changePassword-btn"
        className={classes.button}
        type={'link'}
        disabled={false}
      >
        Cambiar contraseña
      </Button>
    </Box>
  )
}

export default AccountData
