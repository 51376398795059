import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal.component'
import { Box, makeStyles } from '@material-ui/core'
import Card from './Card.component'
import Button from './button.component'
import { useLocation, globalHistory } from '@reach/router'
import { navigate } from 'gatsby'
import { useAppContext } from '../../context/AppContext'
import setClearData from '../../context/modalExitPrompt/actions/setClearData'
import setConfirmExitPrompt from '../../context/modalExitPrompt/actions/setConfirmExitPrompt'

const useStyle = makeStyles((theme) => ({
  messageModal: {
    width: '600px',
    height: 'fit-content',
    padding: '4rem 3rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '3.5rem 1rem',
      margin: '0 auto',
    },
    '& h1': {
      textAlign: 'center',
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '30px',
      color: theme.palette.grayScale.g500,
      marginBottom: '2rem',
      fontFamily: 'Poppins'
    },
    '& h4': {
      textAlign: 'center',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '27px',
      color: theme.palette.grayScale.g600,
      marginBottom: '2.5rem',
      fontFamily: 'Poppins',
      [theme.breakpoints.down('sm')]: {
        padding: '0 1.125rem',
      },
    },
  },
  modalContainer: {
    width: '38rem',
    height: '20.875rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    '& svg': {
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      height: 'fit-content',
      transform: 'translate(-50%, -80%)',
    },
  },
  closeButton: {
    width: '1.5rem',
    height: '1.5rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      fontSize: theme.typography.size.small,
      fontWeight: theme.typography.weight.semibolder,
      height: '3rem',
      '&.secondary': {
        marginRight: '1rem',
      },
    },
  },
}))

// Used to prompt the user before navigating away from a page.
// When your application enters a state that should prevent the user from navigating away
// (like a form is half-filled out), render a Confirm Modal

let targetPath = ''
const ModalExitPrompt = ({
  subtitle = '',
  title = '¿Confirmás que querés salir?',
  primaryActionText = 'Sí, salir',
  secondaryActionText = 'No',
  showModal = () => false,
  manualDependency = false,
}) => {
  const classes = useStyle()
  const [open, setOpen] = useState(false)
  const { modalExitPrompt, modalExitPromptDispatch, budget } = useAppContext()
  const curLocation = useLocation()

  useEffect(() => {
    const removeListener = globalHistory.listen(({ action, location }) => {
      const {
        state: { preventExitPrompt = false },
      } = location
      const isNewRoute = action === 'PUSH'
      const targetRoute = `${location.pathname}${location.search}`
      const isNotCurrentRoute = !targetRoute.includes(
        `${curLocation.pathname}${curLocation.search}`
      )
      const navigateIsNotFromModal = !location.state.salir
      const routeHasChange = isNewRoute && isNotCurrentRoute
      if (
        routeHasChange &&
        navigateIsNotFromModal &&
        showModal(
          `${curLocation.pathname}${curLocation.search}`,
          targetRoute,
          preventExitPrompt
        )
      ) {
        targetPath = `${location.pathname}${location.search}`
        setOpen(true)
        navigate(`${curLocation.pathname}${curLocation.search}`)
      }
    })
    return () => {
      removeListener()
    }
  }, [curLocation.pathname, modalExitPrompt, budget])

  useEffect(() => {
    // la prop manualDependency puede ser utilizada para activar al modal cuando no hay cambio de rutas
    modalExitPrompt.formHasData && setOpen(true)
  }, [manualDependency])

  const handleClose = () => {
    setOpen(false)
    targetPath = ''
  }
  const handleConfirm = () => {
    setClearData(modalExitPromptDispatch)
    setConfirmExitPrompt(modalExitPromptDispatch, true)
    setOpen(false)
    navigate(targetPath, {
      state: { salir: true },
    })
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      classesByProp={classes}
      onBackdropClick={handleClose}
    >
      <Card className={classes.messageModal}>
        <h1>{title}</h1>
        <h4>{subtitle}</h4>
        <Box className={classes.buttonContainer}>
          <Button
            data-test-id="sale-car-exit-modal-primary-btn"
            className="secondary"
            onClick={() => handleClose()}
            secondary
          >
            {secondaryActionText}
          </Button>
          <Button
            data-test-id="ale-car-exit-modal-secondary-btn"
            onClick={() => handleConfirm()}
            primary
          >
            {primaryActionText}
          </Button>
        </Box>
      </Card>
    </Modal>
  )
}

ModalExitPrompt.propTypes = {
  title: PropTypes.string.isRequired || PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  when: PropTypes.bool,
}

export default ModalExitPrompt
