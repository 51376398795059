import React, { useState } from 'react'
import { Box, makeStyles, Divider } from '@material-ui/core'
import clsx from 'clsx'
import TextField from '../common/TextField.component'
import MaskedTextField from '../common/MaskedTextField.component'
import AutocompleteSelect from '../common/AutocompleteSelect.component'
import Button from '../common/button.component'
import DatePicker from '../common/Datepicker.component'
import { scrollTo } from '../../utils/utils'
import { ARGENTINA } from '../../utils/constants'

const PHONE_MASK = new Array(10).fill(/[1-9]/)
const DNI_MASK = new Array(8).fill(/\d/)

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '31.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '21.875rem',
    },
  },
  textInput: {
    width: '100%',
    marginTop: '2.4375rem',
    '& input': {
      padding: '0.9063rem 1.5rem',
    },
    '& label': {
      fontSize: '18px',
      paddingLeft: '1rem !important',
      color: `${theme.palette.grayScale.g600} !important`,
      transform: 'translate(.438rem, 1rem) scale(0.8)',
    },
    '& fieldset': {
      borderColor: theme.palette.grayScale.g300,
    },
  },
  datePicker: {
    marginTop: '2.4375rem',
  },
  disabledInput: {
    '& div': {
      backgroundColor: theme.palette.grayScale.g100,
    },
  },
  dropdownInput: {
    marginTop: '0.9375rem',
    '& label': {
      paddingLeft: '5px',
    },
    '& .MuiAutocomplete-input': {
      height: '1.8125rem',
      marginLeft: '10px',
    },
  },
  divider: {
    marginTop: '2rem',
  },
  optionStyles: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '100%',
    padding: '10px 20px',
    margin: 0,
    color: theme.palette.grayScale.g500,
  },
  paperStyles: {
    '& .MuiAutocomplete-paper': {
      '@media (max-width: 796px) and (min-width: 480px)': {
        marginRight: 1,
      },
      '@supports ( -moz-appearance:none )': {
        marginRight: 1,
      },
      borderRadius: theme.borderRadius.large,
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.25)',
      border: `1px solid ${theme.palette.grayScale.g200}`,
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: '8.375rem',
      marginBottom: '6rem',
      marginLeft: 'auto',
    },
    marginTop: '2rem',
    marginBottom: '3rem',
  },
  noClearIcon: {
    display: 'none',
  },
}))

const PersonalData = ({
  initialFields,
  maritalStatus = [],
  provinces = [],
  loadingProvinces,
  cities = [],
  loadingCities,
  setSelectedProvince = () => {},
  isMobile,
  validateFields = () => {},
  onFormEmpty = () => {},
  sendUpdate = () => {},
}) => {
  const classes = useStyles()

  const [fields, setFields] = useState(initialFields)
  const [formSubmited, setFormSubmited] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  const validateEmptyForm = (formData) => {
    const isEmpty =
      !formData.phoneNumber &&
      !formData.nroDoc &&
      !formData.birth_date &&
      !formData.marital_status &&
      !formData.province &&
      !formData.city
    onFormEmpty(isEmpty)
  }

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields }
    newFields[key] = value || null
    setFields(newFields)
    validateEmptyForm(newFields)
    formSubmited && validateForm(newFields)
  }

  const handleDropdownChange = (key, value) => {
    const newFields = { ...fields }
    if (key === 'province') {
      setSelectedProvince(value?.id[0] || '')
      newFields.city = ''
    }
    newFields[key] = value?.id[0] || ''
    setFields(newFields)
    validateEmptyForm(newFields)
  }

  const handleScroll = (id) => {
    if (!isMobile) return
    const element = document.getElementById(id)
    const rect = element.getBoundingClientRect()

    scrollTo(rect, 200)
  }

  const validateForm = (newFields) => {
    const { valid, errors } = validateFields(newFields)
    setErrorMessages(errors)
    return valid
  }

  const handleSubmit = () => {
    setFormSubmited(true)
    const isValid = validateForm(fields)
    if (isValid) {
      sendUpdate(fields)
      setFormSubmited(false)
    }
  }

  return (
    <Box className={classes.container}>
      <TextField
        className={clsx(classes.textInput, classes.disabledInput)}
        label="Nombre"
        value={fields.firstname}
        onChange={() => {}}
        variant="outlined"
        disabled
      />
      <TextField
        className={clsx(classes.textInput, classes.disabledInput)}
        label="Apellido"
        value={fields.lastname}
        onChange={() => {}}
        variant="outlined"
        disabled
      />
      <MaskedTextField
        id="textfield-phone"
        className={classes.textInput}
        label="Teléfono"
        value={fields.phoneNumber}
        error={errorMessages && !errorMessages.phoneNumber.valid}
        helperText={errorMessages && errorMessages.phoneNumber.error}
        onChange={(e) => handleTextFieldChange('phoneNumber', e.target.value)}
        onFocus={() => handleScroll('textfield-phone')}
        variant="outlined"
        mask={PHONE_MASK}
        autoComplete="off"
        type="tel"
      />
      <Divider classes={{ root: classes.divider }} />
      <MaskedTextField
        id="textfield-nroDoc"
        className={classes.textInput}
        label="DNI (opcional)"
        value={fields.nroDoc || null}
        error={errorMessages && !errorMessages.nroDoc.valid}
        helperText={errorMessages && errorMessages.nroDoc.error}
        onChange={(e) => handleTextFieldChange('nroDoc', e.target.value)}
        onFocus={() => handleScroll('textfield-nroDoc')}
        variant="outlined"
        mask={DNI_MASK}
        autoComplete="off"
        type="tel"
      />
      <DatePicker
        id="textfield-birth_date"
        label="Fecha de nacimiento (opcional)"
        placeholderText="dd/mm/aaaa"
        dateFormat="DD/MM/YYYY"
        maxDate={new Date()}
        toolbarTitle="FECHA DE NACIMIENTO"
        toolbarFormat="ddd, MMM DD"
        value={fields.birth_date}
        onChange={(value) => {
          value?._isValid
            ? handleTextFieldChange('birth_date', value)
            : handleTextFieldChange('birth_date', null)
        }}
        onFocus={() => handleScroll('textfield-birth_date')}
        classNameTextfield={classes.datePicker}
      />
      <AutocompleteSelect
        id="select-civil"
        classNameTextfield={classes.textInput}
        getOptionLabel={(option) => option.label || option}
        label="Estado civil (opcional)"
        className={classes.dropdownInput}
        receivedValue={fields.marital_status}
        onChange={(val) => handleDropdownChange('marital_status', val)}
        onFocus={() => handleScroll('select-civil')}
        options={maritalStatus}
        classes={{
          option: classes.optionStyles,
          popper: classes.paperStyles,
          clearIndicator: classes.noClearIcon,
        }}
        disableClearable={false}
        optional
      />
      <Divider classes={{ root: classes.divider }} />
      <TextField
        className={clsx(classes.textInput, classes.disabledInput)}
        label="País"
        value={ARGENTINA}
        onChange={() => {}}
        variant="outlined"
        disabled
      />
      <AutocompleteSelect
        id="select-province"
        classNameTextfield={classes.textInput}
        getOptionLabel={(val) => val.label || val}
        label="Provincia (opcional)"
        loading={loadingProvinces}
        className={classes.dropdownInput}
        receivedValue={fields.province ? provinces.find((opt) => opt.key === fields.province).label : ''}
        onChange={(val) => handleDropdownChange('province', val)}
        onFocus={() => handleScroll('select-province')}
        options={provinces}
        classes={{
          option: classes.optionStyles,
          popper: classes.paperStyles,
          clearIndicator: classes.noClearIcon,
        }}
        disableClearable={false}
        optional
      />
      <AutocompleteSelect
        id="select-city"
        classNameTextfield={classes.textInput}
        getOptionLabel={(val) => val.label || val}
        label="Localidad (opcional)"
        loading={loadingCities}
        className={classes.dropdownInput}
        receivedValue={fields.city ? cities.find((opt) => opt.key === fields.city).label : ''}
        onChange={(val) => handleDropdownChange('city', val)}
        onFocus={() => handleScroll('select-city')}
        options={cities}
        classes={{
          option: classes.optionStyles,
          popper: classes.paperStyles,
          clearIndicator: classes.noClearIcon,
        }}
        disabled={!fields.province}
        disableClearable={false}
        optional
      />
      <Button className={classes.button} onClick={handleSubmit} primary>
        Guardar
      </Button>
    </Box>
  )
}

export default PersonalData
