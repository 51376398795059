import gql from 'graphql-tag';

export const GET_BUDGET_GENERATED_BY_USER_ID = gql`
  query GetBudgetGeneratedByUserId($id: String) {
    budgetGeneratedByUserId(id: $id) {
        image
        date
        brand
        model
        version
        year
        km
        fuel
        id
        is0km
        color {
              id
              name
              hex
            }
        pdf
        paymentData {
          id
          amount
          pdfUrl
        }
    }
  }
`;
