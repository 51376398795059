import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import TextField from './TextField.component';

const MaskedTextField = ({ mask, guide, InputProps, ...textFieldProps }) => {
  return (
    <TextField
      {...textFieldProps}
      inputProps={{ mask, guide }}
      InputProps={{
        inputComponent: TextMask,
        ...InputProps
      }}
    />
  );
};

MaskedTextField.propTypes = {
  mask: PropTypes.any,
  guide: PropTypes.bool,
};

export default MaskedTextField;

const TextMask = (props) => {
  const { inputRef,mask, guide = false, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={guide}
    />
  );
};

TextMask.propTypes = {
  inputRef: PropTypes.func,
  mask: PropTypes.array,
};

