import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import Card from '../common/Card.component';
import { CarMedia, CarInfo, CarLeyend, CarInfoBlock } from './components';
import CarName from './components/CarName.component ';
import CarPrice from './components/CarPrice.component ';
import CarPill from './components/CarPill.component';
import CarWatermark from './components/CarWatermark.component';
import { STOCK_STATES } from '../../utils/constants';
import { NEW_PALETTE } from '../../assets/theme';

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    Width: '100%',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grayScale.g300}`,
  },
  mainContainerComparador: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    Width: '100%',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.grayScale.g300}`,
  },
  compactContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  compactCarMedia: {
    width: '25%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'relative',
  },
  description: {
    padding: theme.spacing(2),
  },
  compactDescription: {
    width: '75%',
    padding: '8px',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.024em',
      color: NEW_PALETTE.neutral.gray07,
    },
  },
  separator: {
    borderBottom: `2px solid ${theme.palette.grayScale.g300}`,
    margin: `0 ${theme.spacing(1)}px`,
  },
  footer: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  compactFooter: {
    display: 'flex',
    flexDirection: 'column',
  },
  carLeyend: {
    display: 'flex',
    textAlign: 'right',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  compactCarLeyend: {
    display: 'flex',
    textAlign: 'right',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(1),
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '110px',
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
    },
  },
  descriptionNotLoggedIn: {
    margin: '24px 25px',
  },
  footerNotLoggedIn: {
    padding: '16px 25px 0px 25px',
    paddingTop: 0,
  },
  mainContainerNotLoggedIn: {
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'initial',
    },
  },
  carPrinceNotLoggedIn: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 'initial',
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  onStockPill: {
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    backgroundColor: '#EFE5FF',
    padding: '4px 8px',
  },
}));

const CarCard = ({
  car,
  className,
  compact,
  onCardClick,
  disableHover = false,
  isLoggedIn,
  isComparador = false,
  showInfo = true,
  onStock
}) => {
  const classes = useStyle();
  let isCarMediaDragging = false;
  const onCarMediaDrag = (isDragging) => {
    isCarMediaDragging = isDragging;
  };
  const isReserved = car.stock?.length ? car.stock?.every(s => s.estado === STOCK_STATES.RESERVED) : false;

  const handleOnClick = () => !isCarMediaDragging && onCardClick(car);

  return isLoggedIn ? (
    <Card data-test-id='car-card'
      hoverable={!disableHover}
      className={clsx(
        classes.mainContainer, className)} onClick={handleOnClick}>
      <Box className={clsx({ [classes.compactContent]: compact })}>
        <Box className={clsx({ [classes.compactCarMedia]: compact })}>
          <CarMedia photos={car.images} onDrag={onCarMediaDrag} isMobile={compact} isReserved={isReserved} />
        </Box>
        <Box className={clsx(classes.description, { [classes.compactDescription]: compact })}>
          <CarName car={car} isMobile={compact} />
          <CarInfo car={car} isMobile={compact} />
          <CarPill car={car} isMobile={compact} isLoggedIn={isLoggedIn} isComparador={isComparador} />
          {compact ?
            <>
              <CarPrice car={car} isMobile={compact} isLoggedIn={isLoggedIn}/>
            </> : null
          }
        </Box>
      </Box>
      {car.finalPrice && !compact &&
        <Box>
          {!compact ? <Box className={classes.separator} /> : null}
          <Box className={clsx(classes.footer, { [classes.compactFooter]: compact })}>
            <CarPrice car={car} isLoggedIn={isLoggedIn}/>
            <Box className={clsx(
              { [classes.carLeyend]: !compact },
              { [classes.compactCarLeyend]: compact })}>
            </Box>
          </Box>
        </Box>
      }
      {compact && isReserved &&
        <Box>
          <CarWatermark isMobile={compact} />
        </Box>
      }
    </Card>
  ) :
    <Card data-test-id='car-card'
      hoverable={!disableHover}
      className={clsx(
        isComparador
          ? classes.mainContainerComparador
          : classes.mainContainer, className, { [classes.mainContainerNotLoggedIn]: compact })} onClick={handleOnClick}>
      <Box className={clsx({ [classes.compactContent]: compact })}>
        <Box className={clsx({ [classes.compactCarMedia]: compact })}>
          <CarMedia photos={car.images} onDrag={onCarMediaDrag} isMobile={compact} isReserved={isReserved} />
        </Box>
        <Box className={clsx(
          { [classes.descriptionNotLoggedIn]: !compact },
          { [classes.compactDescription]: compact })}
        >
          <CarInfoBlock car={car} isMobile={compact} className={classes.infoBlock} />
          {showInfo && <CarPill car={car} isMobile={compact} isLoggedIn={isLoggedIn} isComparador={isComparador}/>}
          { compact && !isComparador &&
            <CarPrice isMobile={compact} car={car} isLoggedIn={isLoggedIn} className={classes.carPrinceNotLoggedIn} /> }
          {onStock && <OnStock />}
        </Box>
      </Box>
      {car.finalPrice && !compact && !isComparador &&
        <Box>
          <Box className={clsx({ [classes.separator]: !compact })} />
          <Box className={clsx(classes.footerNotLoggedIn, { [classes.compactFooter]: compact })}>
            <CarPrice car={car} isLoggedIn={isLoggedIn}/>
            <Box className={clsx(
              { [classes.carLeyend]: !compact },
              { [classes.compactCarLeyend]: compact })}>
              <CarLeyend carLot={car.carLot?.name} resalePrice={car.resalePrice} />
            </Box>
          </Box>
        </Box>
      }
      {compact && isReserved &&
        <Box>
          <CarWatermark isMobile={compact} />
        </Box>
      }
    </Card>;
};

const OnStock = () => {
  const classes = useStyle();
  return (
    <Box>
      <span className={classes.onStockPill}>En stock</span>
    </Box>
  );
};

CarCard.propTypes = {
  car: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  onCardClick: PropTypes.func,
};

CarCard.defaultProps = {
  car: null,
  compact: false,
  onCardClick: () => { }
};

export default CarCard;
