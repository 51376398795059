import React, { useEffect, useState } from 'react'
import PersonalData from '../../components/account/PersonalData.component'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { validateMinLength } from '../../utils/validators.utils'
import { UPDATE_USER } from '../../mutations'
import { GET_PROVINCES, GET_CITIES, GET_USER, GET_MARITAL_STATUS } from '../../queries'
import ErrorMessage from '../../components/common/ErrorMessage.component'
import { ListaCatalogoLoading } from '../../components/catalogo/lista/ListaCatalogo.component'
import { ID_ARG } from '../../utils/constants'
import LoadingModal from '../../components/common/LoadingModal.component'

const ERROR_MESSAGE_MODAL = '¡Algo pasó!'
const ERROR_LEYEND_SECONDARY_MODAL = 'Los datos no se guardaron, ¿lo intentás de nuevo?'
const BTN_TEXT_MODAL = 'Volver a intentar'
const BTN_TEXT_MODAL_OK = 'OK'
const MESSAGE_MODAL_SECONDARY = 'Estamos guardando tus datos.'
const OK_MESSAGE_MODAL = '¡Listo!'
const OK_LEYEND_SECONDARY_MODAL = 'Ya guardamos tus datos.'

const mapDropdown = (elem) => ({
  label: elem.nombre,
  key: elem.id,
  value: { name: elem.nombre, id: [elem.id] },
})

const PersonalDataContainer = ({
  profile,
  isMobile,
  onFormEmpty = () => {},
  resetModalContext = () => {},
  handleFormEmpty = () => {},
}) => {
  const [initialFields, setInitialFields] = useState({})
  const [maritalStatus, setMaritalStatus] = useState([])
  const [provinces, setProvinces] = useState([])
  const [selectedProvince, setSelectedProvince] = useState('')
  const [cities, setCities] = useState([])
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    resetModalContext()
  }, [])

  useEffect(() => {
    if (selectedProvince) {
      refetch()
    }
  }, [selectedProvince])

  const validateFields = (fields) => {
    const isValidPhone = validateMinLength(
      fields.phoneNumber || '',
      10,
      (minLength) => (
        <ErrorMessage icon={false}>Tiene que tener {minLength} caracteres como mínimo.</ErrorMessage>
      ),
      <ErrorMessage icon={false}>Escribí tu teléfono.</ErrorMessage>
    )

    // Pregunto si es vacio por que es un campo opcional
    let isValidDni = { valid: true, error: '' }
    if (fields.nroDoc) {
      isValidDni = validateMinLength(fields.nroDoc, 7, (minLength) => (
        <ErrorMessage icon={false}>Tiene que tener al menos {minLength} caracteres.</ErrorMessage>
      ))
    }

    const valid = isValidPhone.valid && isValidDni.valid

    const errors = {
      phoneNumber: isValidPhone,
      nroDoc: isValidDni,
    }

    return { valid, errors }
  }

  const { data: dataMaritalStatus } = useQuery(GET_MARITAL_STATUS, {
    onCompleted() {
      const status = dataMaritalStatus.maritalStatus.map((elem) => ({
        label: elem,
        key: elem,
        value: { name: elem, id: [elem] },
      }))
      setMaritalStatus(status)
    },
  })

  const { data: dataProvinces, loading: loadingProvinces } = useQuery(GET_PROVINCES, {
    variables: { idCountry: ID_ARG },
    onCompleted() {
      const prov = dataProvinces.provinces.map(mapDropdown)
      setProvinces(prov)
    },
  })

  const {
    data: dataCities,
    loading: loadingCities,
    refetch,
  } = useQuery(GET_CITIES, {
    skip: !selectedProvince,
    variables: { idCountry: ID_ARG, idProvince: selectedProvince },
    onCompleted() {
      if (dataCities) {
        const cit = dataCities.cities.map(mapDropdown)
        setCities(cit)
      }
    },
  })

  const { data: dataUser } = useQuery(GET_USER, {
    skip: !dataProvinces,
    variables: { email: profile.email },
    onCompleted() {
      if (dataUser) {
        const {
          firstname,
          lastname,
          phone: phoneNumber,
          nroDoc,
          birth_date,
          marital_status,
          province,
          city,
        } = dataUser.getUser

        setInitialFields({
          firstname,
          lastname,
          phoneNumber,
          nroDoc,
          birth_date,
          marital_status,
          country: ID_ARG,
          province,
          city,
        })

        if (province) {
          setSelectedProvince(province)
        }
      }
    },
    fetchPolicy: 'network-only',
  })

  const [updateUser, { loading, data }] = useMutation(UPDATE_USER, {
    onError: () => {
      setOpenModal(true)
    },
    onCompleted: () => {
      setOpenModal(true)
    },
  })

  const sendUpdate = (userData) => {
    setOpenModal(true)
    updateUser({ variables: { email: profile.email, userData } })
    handleFormEmpty(true)
  }

  const finishedLoading = !!initialFields.firstname && (!initialFields.province || !!cities.length)

  return (
    <>
      <LoadingModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        handleCloseModalClick={() => setOpenModal(false)}
        updatedSuccess={Boolean(data?.updateUser?.success)}
        updating={loading}
        message={' '}
        leyend={MESSAGE_MODAL_SECONDARY}
        errorMessage={ERROR_MESSAGE_MODAL}
        errorLeyendSecondary={ERROR_LEYEND_SECONDARY_MODAL}
        okMessage={OK_MESSAGE_MODAL}
        okLeyend={OK_LEYEND_SECONDARY_MODAL}
        btnTxt={BTN_TEXT_MODAL}
        btnTxtOk={BTN_TEXT_MODAL_OK}
        customVisibility
      />
      {finishedLoading ? (
        <PersonalData
          initialFields={initialFields}
          maritalStatus={maritalStatus}
          provinces={provinces}
          loadingProvinces={loadingProvinces}
          cities={cities}
          loadingCities={loadingCities}
          setSelectedProvince={setSelectedProvince}
          isMobile={isMobile}
          validateFields={validateFields}
          onFormEmpty={onFormEmpty}
          sendUpdate={sendUpdate}
        />
      ) : (
        <ListaCatalogoLoading />
      )}
    </>
  )
}

export default PersonalDataContainer
