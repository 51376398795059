import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from './TextField.component'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker'
import {
  InputAdornment,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es'
import clsx from 'clsx'
import EventIcon from '@material-ui/icons/Event'

moment.updateLocale('es', {
  week: {
    dow: 1,
  },
})

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& #eventIcon': {
      color: theme.palette.grayScale.g400,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grayScale.g300,
        borderRadius: '1.5625rem',
      },
      '& .MuiInputAdornment-root': {
        '& button': {
          color: theme.palette.grayScale.g400,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.primary.main2,
        },
      },
      '&.Mui-error': {
        '& fieldset': {
          borderColor: theme.palette.error.main,
        },
      },
      '&.Mui-disabled': {
        '& fieldset': {
          backgroundColor: theme.palette.grayScale.g01,
          borderColor: theme.palette.grayScale.g300,
        },
      },
    },
    '& input': {
      fontSize: theme.typography.size.small,
      height: '2rem',
      padding: '0.5rem 1.25rem 0.5rem 1.25rem',
      '&:disabled': {
        color: theme.palette.grayScale.g400,
        zIndex: 1,
      },
    },
    '& label': {
      paddingLeft: '1rem',
      fontSize: theme.typography.size.medium,
      color: `${theme.palette.grayScale.g600} !important`,
      transform: 'translate(.438rem, 1rem) scale(0.8)',
    },
  },
  datePicker: {
    '& .MuiPickersDay-root': {
      fontFamily: 'Poppins',
      fontSize: theme.typography.size.little,
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.primary.main2} !important`,
      },
    },
    '& .MuiPickersCalendarHeader-label': {
      fontSize: theme.typography.size.little,
      fontWeight: theme.typography.weight.semibolder,
      color: theme.palette.grayScale.g400,
      fontFamily: 'Poppins',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    '& .MuiDayPicker-weekDayLabel': {
      color: theme.palette.grayScale.g400,
      fontFamily: 'Poppins',
    },
  },
  dialogCalendar: {
    '& .MuiDialog-container': {
      '& .MuiPickersToolbar-root': {
        backgroundColor: theme.palette.primary.main2,
        '& span, & h4, & svg': {
          color: theme.palette.grayScale.g0,
        },
      },
      '& .Mui-selected': {
        backgroundColor: `${theme.palette.primary.main2} !important`,
      },
      '& .MuiDialogActions-root': {
        '& button': {
          fontWeight: theme.typography.weight.semibold,
          color: theme.palette.primary.main2,
        },
      },
      '& .MuiDayPicker-slideTransition': {
        minHeight: '12rem',
      },
    },
  },
  helperTextDatePicker: {
    display: 'block',
    fontSize: '0.6875rem',
    marginTop: '0.25rem',
    marginLeft: '1.25rem',
  },
  label: {
    minWidth: 'max-content',
  },
}))

const DatePicker = ({
  className,
  value,
  onChange,
  disabled,
  label,
  placeholderText = 'DD/MM/AAAA',
  dateFormat = 'dd/MM/yyyy',
  helperText,
  minDate = '1900',
  maxDate = '2100',
  toolbarTitle,
  toolbarFormat,
  classNameTextfield,
  ...otherProps
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePickerMUI
        className={className}
        inputFormat={dateFormat}
        toolbarFormat={toolbarFormat}
        disabled={disabled}
        value={value}
        toolbarTitle={toolbarTitle}
        onChange={onChange}
        PopperProps={{
          placement: 'bottom-end',
          anchorEl: anchorEl,
        }}
        PaperProps={{ className: classes.datePicker }}
        DialogProps={{ className: classes.dialogCalendar }}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onOpen={() => setOpenDialog(true)}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onChange}
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
            label={label}
            variant="outlined"
            className={clsx(classes.textfield, {
              [classNameTextfield]: classNameTextfield,
            })}
            InputProps={
              isMobile
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon
                          id="eventIcon"
                          onClick={() => setOpenDialog(true)}
                        />
                      </InputAdornment>
                    ),
                  }
                : params.InputProps
            }
            inputProps={{
              ...params.inputProps,
              placeholder: !disabled ? placeholderText : '',
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: {
                root: classes.label,
              },
            }}
          />
        )}
        minDate={minDate}
        maxDate={maxDate}
        {...otherProps}
      />
      {helperText && (
        <span className={classes.helperTextDatePicker}>{helperText}</span>
      )}
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  className: PropTypes.node,
}

export default DatePicker
